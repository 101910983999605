<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-detalle-tepsolicitud"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">Detalle Solicitud</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-2">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Solicitudes"
                  data-toggle="tab"
                  href="#Solicitudes"
                  >Solicitud</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Solicitudes"
                  data-toggle="tab"
                  href="#Autorizacion"
                  >Autorizaciones</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Viajes"
                  data-toggle="tab"
                  href="#Viajes"
                  >SETT
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show m-2" id="Solicitudes">
                <div class="row">
                  <div class="col-md-6">
                    <div class="card">
                      <div class="card-header">
                        <h5 class="card-title">Datos Solicitud</h5>
                        <div class="card-tools">
                          <span
                            class="badge"
                            :class="
                              data_solicitud.estado == 1
                                ? 'badge-warning'
                                : data_solicitud.estado == 2
                                ? 'badge-success'
                                : data_solicitud.estado == 3
                                ? 'badge-primary'
                                : data_solicitud.estado == 4
                                ? 'badge-danger'
                                : data_solicitud.estado == 5
                                ? 'badge-primary'
                                : data_solicitud.estado == 6
                                ? 'badge-success'
                                : 'badge-danger'
                            "
                            >{{ data_solicitud.nEstado }}</span
                          >
                        </div>
                      </div>
                      <div class="card-body">
                        <!-- Detalle de la Solicitud -->
                        <div class="row">
                          <p class="col-sm-6 text-bold"># Solicitud:</p>
                          <p class="col-sm-6 description-header">
                            {{ data_solicitud.id }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Tipo Vehículo:</p>
                          <p class="col-sm-6 description-header">
                            {{
                              data_solicitud.tipo_vehiculo
                                ? data_solicitud.tipo_vehiculo.nombre
                                : ""
                            }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Fecha Inicio</p>
                          <p class="col-sm-6 description-header">
                            {{ data_solicitud.fecha_hora_inicio_servicio }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Fecha Fin</p>
                          <p class="col-sm-6 description-header">
                            {{ data_solicitud.fecha_hora_fin_servicio }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Origen</p>
                          <p
                            class="col-sm-6 description-header"
                            v-if="data_solicitud.direccion_origen"
                          >
                            {{ data_solicitud.direccion_origen }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Destino</p>
                          <p
                            class="col-sm-6 description-header"
                            v-if="data_solicitud.direccion_destino"
                          >
                            {{ data_solicitud.direccion_destino }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Ruta</p>
                          <p
                            class="col-sm-6 description-header"
                            v-if="data_solicitud.ruta"
                          >
                            {{ data_solicitud.ruta }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Motivo</p>
                          <p
                            class="col-sm-6 description-header"
                            v-if="data_solicitud.motivo"
                          >
                            {{ data_solicitud.motivo }}
                          </p>
                        </div>
                        <div class="row"
                          v-if="data_solicitud.tipo_transporte !== 1"
                        >
                          <p class="col-sm-6 text-bold">
                            Estado Inspeccion
                          </p>
                          <p
                            class="col-sm-6 description-header"
                            v-if="data_solicitud.nEstadoInspeccion"
                          >
                            {{ data_solicitud.nEstadoInspeccion }}
                          </p>
                        </div>
                        <div class="row"
                          v-if="data_solicitud.tipo_transporte !== 1"
                        >
                          <p class="col-sm-6 text-bold">
                            Tipo Operacion
                          </p>
                          <p
                            class="col-sm-6 description-header"
                            v-if="data_solicitud.nEstadoOperacion"
                          >
                            {{ data_solicitud.nEstadoOperacion }}
                          </p>
                        </div>
                        <div class="row"
                          v-if="data_solicitud.tipo_transporte !== 1"
                        >
                          <p class="col-sm-6 text-bold">
                            Sitio
                          </p>
                          <p
                            class="col-sm-6 description-header"
                            v-if="data_solicitud.sitio"
                          >
                            {{ data_solicitud.sitio.nombre }}
                          </p>
                        </div>
                        <div class="row"
                          v-if="data_solicitud.tipo_transporte !== 1"
                        >
                          <p class="col-sm-6 text-bold">
                            ID Solicitud Padre
                          </p>
                          <p
                            class="col-sm-6 description-header"
                            v-if="data_solicitud.tep_solicitud_padre_id"
                          >
                            {{ data_solicitud.tep_solicitud_padre_id }}
                          </p>
                        </div>
                        <div class="row"
                          v-if="data_solicitud.razon_cancelacion"
                        >
                          <p class="col-sm-6 text-bold">Razón Cancelación</p>
                          <p class="col-sm-6 description-header text-danger">
                            {{ data_solicitud.razon_cancelacion }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card">
                      <div class="card-header">
                        <h5 class="card-title">
                          Pasajeros
                        </h5>
                        <div class="card-tools"></div>
                      </div>
                      <div class="card-body p-0">
                        <table
                          id="califaciones"
                          class="table table-bordered table-striped table-hover table-sm"
                        >
                          <thead>
                            <tr>
                              <th>Pasajero</th>
                              <th>Número Documento</th>
                              <th>Teléfono</th>
                            </tr>
                          </thead>
                          <tbody v-if="data_solicitud.pasajeros">
                            <tr
                              v-for="item in data_solicitud.pasajeros"
                              :key="item.id"
                            >
                              <td>{{ item.nombres }}</td>
                              <td>{{ item.numero_documento }}</td>
                              <td>{{ item.telefono }}</td>
                            </tr>
                          </tbody>
                          <tbody v-else></tbody>
                        </table>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="card-title">
                          Datos contables
                        </h5>
                        <div class="card-tools"></div>
                      </div>
                      <div class="card-body p-0">
                        <table
                          class="table table-bordered table-striped table-hover table-sm"
                        >
                          <thead>
                            <tr>
                              <th class="text-center">Tipo Presupuesto</th>
                              <th class="text-center">Responsable</th>
                              <th class="text-center">Número</th>
                              <th class="text-center">Porcentaje</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in data_solicitud.ceco_afe"
                              :key="item.id"
                            >
                              <td class="text-center">
                                <span class="badge badge-warning"
                                  :class="
                                    item.tipo_presupuesto.numeracion == 1 || item.tipo_presupuesto == 1 ? 
                                    'bg-info' : 
                                    item.tipo_presupuesto.numeracion == 2 || item.tipo_presupuesto == 2 ? 
                                    'bg-navy' :
                                    'bg-lime'
                                  "
                                >
                                  {{ item.nTipoPresupuesto }}
                                </span>
                              </td>
                              <td class="text-center">
                                {{ item.funcionario.nombres }}
                                {{ item.funcionario.apellidos }}
                              </td>
                              <td class="text-center">{{ item.numero ? item.numero.codigo_contable : "" }}</td>
                              <td class="text-right">{{ item.porcentaje }}%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-md-center" v-if="data_solicitud.tipo_transporte == 2">
                  <div class="col-md-12">
                    <div class="card card-info">
                      <div class="card-header">
                        <h3 class="card-title">
                          Vehículo y Conductor
                        </h3>
                      </div>
                      <div class="card-body">
                        <!-- Vehículo -->
                        <div class="row">
                          <div
                            class="
                                col-sm-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                            v-if="data_solicitud.vehiculo_id != null"
                          >
                            <div class="card bg-white d-flex flex-fill">
                              <div
                                class="card-header text-muted border-bottom-0"
                              >
                                Vehículo
                              </div>
                              <div class="card-body pt-0">
                                <div class="row">
                                  <div
                                    :class="
                                      data_solicitud.vehiculo.link_fotografia
                                        ? 'col-md-7'
                                        : 'col-md-10'
                                    "
                                  >
                                    <h2 class="lead">
                                      <b>{{ data_solicitud.vehiculo.placa }}</b>
                                    </h2>
                                    <p
                                      class="text-muted text-sm"
                                      v-if="data_solicitud.transportadora"
                                    >
                                      <b>Transportadora: </b>
                                      {{
                                        data_solicitud.transportadora
                                          .razon_social
                                      }}
                                    </p>
                                    <ul class="ml-4 mb-0 fa-ul text-muted">
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-car"></i
                                        ></span>
                                        Tipo Vehículo:
                                        {{
                                          data_solicitud.tipo_vehiculo.nombre
                                        }}
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    class="col-md-5 text-center"
                                    v-if="
                                      data_solicitud.vehiculo.link_fotografia
                                    "
                                  >
                                    <img
                                      :src="
                                        uri_docs +
                                          data_solicitud.vehiculo
                                            .link_fotografia
                                      "
                                      alt="Vehículo"
                                      class="
                                          img-bordered img-rounded img-fluid
                                        "
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="
                                col-12 col-sm-6 col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                          >
                            <div
                              class="small-box bg-secondary"
                              style="min-height: 100px"
                            >
                              <div class="inner">
                                <h4>No hay vehiculo</h4>
                              </div>
                              <div class="icon">
                                <i class="fas fa-car"></i>
                              </div>
                            </div>
                          </div>
                          <!-- Conductor -->
                          <div
                            class="
                                col-sm-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                            v-if="data_solicitud.conductor_id != null"
                          >
                            <div class="card bg-white d-flex flex-fill">
                              <div
                                class="card-header text-muted border-bottom-0"
                              >
                                Conductor
                              </div>
                              <div class="card-body pt-0">
                                <div class="row">
                                  <div
                                    :class="
                                      data_solicitud.conductor.link_fotografia
                                        ? 'col-md-7'
                                        : 'col-md-10'
                                    "
                                  >
                                    <h2 class="lead">
                                      <b
                                        >{{ data_solicitud.conductor.nombres }}
                                        {{ data_solicitud.conductor.apellidos }}
                                      </b>
                                    </h2>
                                    <p
                                      v-if="data_solicitud.encuesta"
                                      class="text-muted text-sm"
                                    >
                                      <b>Calificación: </b>
                                      {{ data_solicitud.encuesta.calificacion }}
                                      <i class="fas fa-star"></i>
                                    </p>
                                    <p v-else class="text-muted text-sm">
                                      <b>Calificación: </b>
                                      Sin calificación
                                    </p>
                                    <p class="text-muted text-sm">
                                      <b>Documento: </b>
                                      {{
                                        data_solicitud.conductor
                                          .numero_documento
                                      }}
                                    </p>
                                    <ul class="ml-4 mb-0 fa-ul text-muted">
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-envelope"></i
                                        ></span>
                                        E-mail:
                                        {{ data_solicitud.conductor.email }}
                                      </li>
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-mobile"></i
                                        ></span>
                                        Celular Principal:
                                        {{
                                          data_solicitud.conductor
                                            .celular_principal
                                        }}
                                      </li>
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-phone"></i
                                        ></span>
                                        Celular Alterno:
                                        {{
                                          data_solicitud.conductor
                                            .celular_alterno
                                        }}
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    class="col-md-5 text-center"
                                    v-if="
                                      data_solicitud.conductor.link_fotografia
                                    "
                                  >
                                    <img
                                      :src="
                                        uri_docs +
                                          data_solicitud.conductor
                                            .link_fotografia
                                      "
                                      alt="Conductor"
                                      class="
                                          img-bordered img-rounded img-fluid
                                        "
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="
                                col-12 col-sm-6 col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                          >
                            <div
                              class="small-box bg-secondary"
                              style="min-height: 100px"
                            >
                              <div class="inner">
                                <h4>No hay conductor</h4>
                              </div>
                              <div class="icon">
                                <i class="fas fa-user"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="Autorizacion"
                class="tab-pane"
                v-if="data_solicitud.autorizaciones"
              >
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          class="table table-sm table-bordered table-hover table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Tipo</th>
                              <th>Funcionario</th>
                              <th>Fecha Autorizo</th>
                              <th>Estado</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="autor in data_solicitud.autorizaciones"
                              :key="autor.id"
                            >
                              <td>{{ autor.nTipoAutorizacion }}</td>
                              <td>{{ autor.user.name }}</td>
                              <td>{{ autor.fecha_autorizacion }}</td>
                              <td>
                                <span
                                  class="badge"
                                  :class="
                                    autor.estado == 1
                                      ? 'badge-success'
                                      : 'badge-danger'
                                  "
                                  >{{ autor.nEstado }}</span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="Viajes"
                class="tab-pane"
                v-if="data_solicitud.viajes.length > 0"
              >
                <div class="card-body p-0">
                  <div class="container px-2 pt-2">
                    <div class="row justify-content-md-center">
                      <div class="col-md-12">
                        <div class="card card-info">
                          <div class="card-header">
                            <h3 class="card-title">Viajes</h3>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12 px-4 pt-2 pb-2">
                                <table id="users"
                                  class="table table-bordered table-striped table-hover table-sm mb-0"
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        #
                                      </th>
                                      <th>
                                        Conductor
                                      </th>
                                      <th>
                                        Vehículo
                                      </th>
                                      <th>Odómetro Inicial</th>
                                      <th>Odómetro Final</th>
                                      <th>
                                        Estado
                                      </th>
                                      <th>
                                        Empresa Transportadora
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in data_solicitud.viajes"
                                      :key="item.id"
                                    >
                                      <td>
                                        {{ item.id }}
                                      </td>

                                      <td v-if="item.conductor">
                                        {{ item.conductor.nombres
                                        }}{{ item.conductor.apellidos }}
                                      </td>
                                      <td v-else></td>
                                      <td v-if="item.vehiculo">
                                        {{ item.vehiculo.placa }}
                                      </td>
                                      <td v-else></td>
                                      <td>
                                        {{ item.odometro_ini }}
                                      </td>
                                      <td>
                                        {{ item.odometro_fin }}
                                      </td>

                                      <td class="text-center">
                                        <div v-if="item.estado == 1">
                                          <span class="badge badge-info">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 2">
                                          <span class="badge badge-warning">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 3">
                                          <span class="badge badge-success">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 4">
                                          <span class="badge badge-primary">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 5">
                                          <span class="badge badge-info">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 6">
                                          <span class="badge badge-danger">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 7">
                                          <span class="badge badge-danger">
                                            {{ item.nEstado }}
                                          </span>
                                        </div>
                                        <div v-else-if="item.estado == 8">
                                          <span class="badge badge-primary">
                                            {{
                                              item.nEstado +
                                                "/ " +
                                                item.tep_solicitud_padre_id
                                            }}
                                          </span>
                                        </div>
                                      </td>
                                      <td v-if="item.transportadora">
                                        {{ item.transportadora.razon_social }}
                                      </td>
                                      <td v-else></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="tab-pane" id="Viajes">
                <div class="card-body">
                  <div class="info-box bg-gradient-warning">
                    <span class="info-box-icon"
                      ><i class="fas fa-exclamation-triangle"></i
                    ></span>
                    <div class="info-box-content">
                      <span class="info-box-text">No tiene viajes</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TepSolicitudDetalle",
  components: {},
  data() {
    return {
      data_solicitud: {
        viajes: {},
      },
      soliditud_id: null,
      mensaje_error: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    async llenar_modal(item) {
      this.data_solicitud = item;
    },
    getImage(url) {
      window.open(this.uri_docs + url, "_blank");
    },
  },
};
</script>
